/**
 * Created by andrew.stalker on 04/11/2015.
 * Functions required by the picking page
 */
/* globals getRemainingCasksByAllocationType, disableBackButton, createBarcodeObject, isCaskValidInAllocation,
updateAllocationCounter, getBarcodeFromBarrelId, barcodeToData, searchListForItem, storeTransaction, attachFixedFocus,
 attachInputEvents, countScannedCasks, config, scrollList, sessionValidate, pickingAllocation, saveGenericTransaction */
var drillWarehouse,
  drillBay,
  drillRack,
  drillLevel,
  currentDrill,
  previousDrill,
  drillCount;
  //drillCasks; //todo this is not needed globally i don't think.

function generatePick(requiredDrill,identity,back) {
  "use strict";
  var elements, nextDrill, drillLabel, scanEntry;
  currentDrill = requiredDrill;

  disableBackButton();
  switch (requiredDrill) {
    case "warehouse":
      elements = getWarehouses();
      nextDrill = "bay";
      drillLabel = "W/H";
      break;
    case "bay":
      if(!back) drillWarehouse = identity;
      elements = getBays();
      nextDrill = "rack";
      previousDrill = "warehouse";
      drillLabel = getDrillLabel(requiredDrill);
      break;
    case "rack":
      if(!back) drillBay = identity;
      elements = getRacks();
      nextDrill = "level";
      previousDrill = "bay";
      drillLabel = getDrillLabel(requiredDrill);
      break;
    case "level":
      if(!back) drillRack = identity;
      elements = getLevels();
      nextDrill = null;
      previousDrill = "rack";
      drillLabel = getDrillLabel(requiredDrill);
      break;
    case "null":
      if(!back) drillLevel = identity;
      previousDrill = "level";
      scanEntry = true;
      break;
  }

  var labels = getLabels();

  var drills = {warehouse:drillWarehouse,
    bay:drillBay,
  rack:drillRack,
  level:drillLevel};

  if(scanEntry) {
    generateScan(previousDrill,drills[previousDrill]);
    return;
  }

  var code ='';
  if(requiredDrill !== "warehouse") {
    var identityLabel = (drills[previousDrill] === "")? "N/A":drills[previousDrill];
    code += "<p>"+getDrillLabel(previousDrill)+": "+identityLabel+"</p>";
  } else {
    code += "<p>All Warehouses:</p>";
  }

  code += '<button data-role="button" class="btn btn-dramscan divscroll dv-up" id="divscrollup"><i class="glyphicon glyphicon-arrow-up"></i></button>';
  code += '<button data-role="button" class="btn btn-dramscan divscroll dv-down down-50" id="divscrolldown"><i class="glyphicon glyphicon-arrow-down"></i></button>';

  code += '<div class="row"><div id="pickingLocationContainer" class="pickingLocationContainer col-xs-10">';

  if(requiredDrill !== "warehouse") {
    code += '<button class="btn btn-dramscan btl-lg btn-block" type="button" onclick="generatePick(\''+previousDrill+'\',\''+drills[previousDrill]+'\',true)"><span class="glyphicon glyphicon-arrow-left"></span> Back</button>';
  }

  var all = 0;
  code +='';

  for(var i = 0; i < elements.length; i++) {
    //create button
    // shows correct label, identity, cask label and count. link allows generatepick for next drill.
    code += createPickButton(elements[i].identity, elements[i].count, nextDrill, drillLabel, labels.caskLabel);
    all += elements[i].count;
  }
  drillCount = all;

  if(all < 1) {
    code += '<p>No '+labels.caskLabel+'s to pick.</p>';
  }

  code += '<button class="btn btn-dramscan btl-lg btn-block" type="button" onclick="generateScan(\''+currentDrill+'\',\''+drills[currentDrill]+'\')">All '+labels.caskLabel+'s: '+all+'</button>';
  code += '</div></div>';

  $("#programContainer").html(code);

  $("#divscrollup").on("click", function (event) {
    event.preventDefault();
    var amount = "-=64px";
    $("#pickingLocationContainer").animate({
      scrollTop: amount
    });
  });

  $("#divscrolldown").on("click", function (event) {
    event.preventDefault();
    var amount = "+=64px";
    $("#pickingLocationContainer").animate({
      scrollTop: amount
    });
  });
}

function generateScan(drill,drillValue) {
  "use strict";
  var code = '<button class="btn btn-dramscan btn-lg btn-top-left" id="pickButton" onclick="generatePick(\''+drill+'\',\''+drillValue+'\',true)">Back</button>';
  var exitButton = $("#exitbutton");
  var exitButtonCode = exitButton[0];
  exitButton.remove();
  $("#header").append(code);

  $("#pickButton").on("click", function() {
    $("#pickButton").remove();
    $("#header").append(exitButtonCode);
  });

  var labels = getLabels();
  var useSWA = Boolean(localStorage.getItem("settings/useSWA") === "true");

  $("#programContainer").load('templates/ds.pick-scan.template.html', function() {

    // Set the labels.
    $("#scanInputLabel").html('Scan ' + labels.caskLabel + ':');

    // Check and show SWA manual entry
    if (checkAndShowManualBarcodeEntry()) {
      $("#manualScanButton").on('click', function() {
        loadManualCaskPopup(previous_scan, function(valid, manualBarcodeObject) {
          if (valid) {
            // Manual barcode was valid and needs to be saved.
            processPickingScan(manualBarcodeObject, function(validAndSaved) {
              if (validAndSaved) {
                $("#manualCaskPopup-modal").modal("hide");
              }
            });
          }
        });
      });
    }

    attachInputEvents();

    var $scanInput = $('#scanInput');
    $scanInput.focus();

    $("#divscrollup").on("click", function (event) {
      event.preventDefault();
      scrollList("up");
    });

    $("#divscrolldown").on("click", function (event) {
      event.preventDefault();
      scrollList("down");
    });

    showLevelCaskCount();

    createEnterAction('#scanInput', true, function(scannedCask) {
      // enter pressed. barcode scanned

      if(!isSessionValid()) return; // validate session before saving any data.
      var barcodeObject = createBarcodeObject(scannedCask, "scanInput");
      if(barcodeObject.passed) {
        processPickingScan(barcodeObject, function() {
          // nothing to do here.
        });
      }
    });

    attachFixedFocus("#scanInput");
  });
}

/**
 * @description Takes a barcodeObject and processes it against picking, and saves the transaction
 * @param {barcodeObject} barcodeObject
 * @param callback
 * @return {boolean}
 */
function processPickingScan(barcodeObject, callback) {
  "use strict";
  var validInAllocation = false;
  var errorStatus = isCaskValidInAllocation(barcodeObject);
  if (errorStatus) {
    if (errorStatus.stopProgram) {
      return false;
    }
    validInAllocation = (errorStatus.name !=="notOnAllocation");
  } else {
    validInAllocation = true;
  }
  saveGenericTransaction(barcodeObject, errorStatus, "", function (success) {
    if (success) {
      if (!errorStatus) {
        playSuccessSound(true);
      }
      // update this screen
      showLevelCaskCount();
      if (validInAllocation) {
        updateAllocationCounter();
      }
    }
    callback(success);
  });
}

function showLevelCaskCount() {
  "use strict";
  //calculate cask requirement
  var casksRequired = (pickingAllocation.hasOrderLines) ? countTotalRequiredCasks(pickingAllocation.orderLines, pickingAllocation.casks): pickingAllocation.casks.length;

  $("#allocationInfo").html(pickingAllocation.number + " <span id='allocationVersion' class='badge'>"+pickingAllocation.version+"</span> " + countScannedCasks() + "/" + casksRequired + "");
  var tempCasks = [];
  if(currentDrill === "warehouse") {
    //drillCasks = stripCasksOnTrailers(getRemainingCasksByAllocationType());
    tempCasks = stripCasksOnTrailers(getRemainingCasksByAllocationType());
  } else {
    //drillCasks = getCasksForDrill();
    tempCasks = getCasksForDrill();
  }

  //$("#levelInfo").html(drillCasks.length);
  $("#levelInfo").html(tempCasks.length);

  //NEW
  showLevelCasks(Boolean(localStorage.getItem("settings/useSWA") === "true"),tempCasks);
}

function showLevelCasks(useSWA,tempCasks) {
  "use strict";
  var code = "";
  var field = (useSWA)? "barcode":"caskId";
  for(var i = 0; i < tempCasks.length; i++) {
    if(tempCasks[i][field] === "") field = "barcode";
    code += '<tr><td>'+tempCasks[i][field]+'</td></tr>';
  }
  if(tempCasks.length < 1) {
    code = "<tr><td>This Level Is Complete.</td></tr>";
  }
  $("#remainingCasks").html(code);
}

function getDrillLabel(required) {
  "use strict";
  if(required === "warehouse") {
    return "Warehouse";
  }
  var labels = getLabels();
  var warehouses = getLocationWarehouses();
  var warehouse = searchListForItem(warehouses,"warehouse",drillWarehouse);

  switch(warehouses[warehouse].warehouseType) {
    case "R":
      return labels.racked[required];
    case "P":
      return labels.pallet[required];
    default:
      return labels.racked[required];
  }
}

function createPickButton(identity, count, nextDrill, drillLabel, caskLabel) {
  "use strict";
  var identityLabel = (identity === "")? "N/A":identity;
  return '<button class="btn btn-dramscan btl-lg btn-block" type="button" onclick="generatePick(\''+nextDrill+'\',\''+identity+'\')">' +
    '<div class="row text-left"><div class="col-xs-6">'+drillLabel+': '+identityLabel+'</div> <div class="col-xs-6">'+caskLabel+'s: '+count+'</div></div>'+
    '</button>';

}

function getCasksForDrill() {
  "use strict";
  var casks = [];
  var remainingCasks = getRemainingCasksByAllocationType();
  for(var i = 0; i < remainingCasks.length; i++) {
    switch (currentDrill){
      case "bay":
        if(remainingCasks[i].warehouse === drillWarehouse) {
          casks.push(remainingCasks[i]);
        }
        break;
      case "rack":
        if(remainingCasks[i].warehouse === drillWarehouse && remainingCasks[i].bay === drillBay) {
          casks.push(remainingCasks[i]);
        }
        break;
      case "level":
        if(remainingCasks[i].warehouse === drillWarehouse && remainingCasks[i].bay === drillBay && remainingCasks[i].rack === drillRack) {
          casks.push(remainingCasks[i]);
        }
        break;
      default:
        if(remainingCasks[i].warehouse === drillWarehouse && remainingCasks[i].bay === drillBay && remainingCasks[i].rack === drillRack && remainingCasks[i].level === drillLevel) {
          casks.push(remainingCasks[i]);
        }
        break;
    }
  }
  return casks;
}

function stripCasksOnTrailers(remainingCasks) {
  "use strict";
  var trailerWarehouse = localStorage.getItem("settings/trailerWarehouse");
  var strippedCasks = [];
  for(var i=0; i<remainingCasks.length;i++) {
    if(remainingCasks[i].warehouse.toUpperCase() !== trailerWarehouse.toUpperCase()) {
      strippedCasks.push(remainingCasks[i]);
    }
  }
  return strippedCasks;
}

function getWarehouses() {
  "use strict";
  var warehouses = [];
  var remainingCasks = getRemainingCasksByAllocationType();
  var trailerWarehouse = localStorage.getItem("settings/trailerWarehouse");
  for(var i = 0; i < remainingCasks.length; i++) {
    if(remainingCasks[i].warehouse.toUpperCase() !== trailerWarehouse.toUpperCase()) {
      var found = searchListForItem(warehouses,"identity",remainingCasks[i].warehouse);
      if(found !== null) {
        warehouses[found].count ++;
      } else {
        var newWarehouse = {identity:remainingCasks[i].warehouse,
          count:1};
        warehouses.push(newWarehouse);
      }
    }
  }
  return warehouses;
}

function getBays() {
  "use strict";
  var bays = [];
  var remainingCasks = getRemainingCasksByAllocationType();
  for(var i = 0; i < remainingCasks.length; i++) {
    if(remainingCasks[i].warehouse === drillWarehouse) {
      var found = searchListForItem(bays,"identity",remainingCasks[i].bay);
      if(found !== null) {
        bays[found].count ++;
      } else {
        var newBay = {identity:remainingCasks[i].bay,
          count:1};
        bays.push(newBay);
      }
    }
  }
  return bays;
}

function getRacks() {
  "use strict";
  var racks = [];
  var remainingCasks = getRemainingCasksByAllocationType();
  for(var i = 0; i < remainingCasks.length; i++) {
    if(remainingCasks[i].warehouse === drillWarehouse && remainingCasks[i].bay === drillBay) {
      var found = searchListForItem(racks,"identity",remainingCasks[i].rack);
      if(found !== null) {
        racks[found].count ++;
      } else {
        var newBay = {identity:remainingCasks[i].rack,
          count:1};
        racks.push(newBay);
      }
    }
  }
  return racks;
}

function getLevels() {
  "use strict";
  var levels = [];
  var remainingCasks = getRemainingCasksByAllocationType();
  for(var i = 0; i < remainingCasks.length; i++) {
    if(remainingCasks[i].warehouse === drillWarehouse && remainingCasks[i].bay === drillBay && remainingCasks[i].rack === drillRack) {
      var found = searchListForItem(levels,"identity",remainingCasks[i].level);
      if(found !== null) {
        levels[found].count ++;
      } else {
        var newBay = {identity:remainingCasks[i].level,
          count:1};
        levels.push(newBay);
      }
    }
  }
  return levels;
}
